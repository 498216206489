import axios from "axios";
const baseDomain = "https://leadgen.republish.nl/api/sponsors/2182";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    Authorization: `Basic MjAwOjBhOWFjMTg4MDY3ZjNjMjc3ZGU1YzlhMTEwZWRiY2FmMjMyZjg2ZjU=`,
  },
});
export default axiosObj;
